h1{
    text-align: center;
    font-size: 3rem;
    font-weight: 800;
}
.certificateCard{
    display: flex;
    margin: 2rem auto;
    width: 90%;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}
@media (max-width:768px) {
    .certificateCard{
        grid-template-columns: repeat(1,100%);
    }    
}