.contactFormCard {
	margin: 2rem auto;
	width: 50%;
	/* background-color: aqua; */
}
.contactFormCard h1 {
	text-align: left;
    margin-left: 2rem;
	font-size: 3rem;
}
.contactForm{
    flex: 1;
    width: 100%;
}
.Inputs {
	background-color: inherit;
	margin-left: 2rem;
	border: none;
	height: 4rem;
	color: purple;
	padding: 0px 20px;
	font-size: 1.5rem;
	font-weight: 700;
    border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 2rem;
    width: auto;
    max-width: 100%;
}
.Inputs:focus {
	outline: none;
	border-color: #240370;
	background-color: #e0d4fd;
}
.contactForm textarea {
    padding-top: 1rem;
	/* width: 100%; */
	height: 10rem;
    width: 20rem;
    max-width: 100%;
	resize: none;
}
/* .Inputs:hover {
    background-color: #e0d4fd;
} */
.sendBtn {
    /* display: flex;
    justify-content: flex-end; */
    margin-top: 1rem;
    margin-left: 2rem;
}
.invalidInput{
	border: 1px solid #b40e0e;
  background-color: #fddddd;
}
.sent .Inputs{
	color:rgb(156, 156, 156);
}
@media (max-width:768px) {
	.contactFormCard {
		width: 90%;
	}
	.contactFormCard h1 {
		margin-left: 0rem;
		font-size: 2.5rem
	}
	.Inputs {
		margin-left: 1rem;
		width: auto;
		max-width: 100%;
	}
	.contactForm textarea {
		height: 10rem;
		width: 80%;
		resize: none;
	}
	.sendBtn {
		margin-left:1rem;
	}
}