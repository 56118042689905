.centered{
    display: flex;
    justify-content: center;
    align-items: center;
}
.degreeMain{
    width: 80%;
    margin: 2rem auto;
}
.degreeMain h1{
    text-align: center;
    font-size: 3rem;
    font-weight: 800;
}
.degreeCard{
    margin-top: 1rem;
    display: flex;
    align-items: center;
}
.degreeImage{
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    border:5px solid;
}
.degreeImage img{
    height: 10rem;
    width: 10rem; 
}
.degreeWrapper{
    margin-left: 2rem;
    width: 75%;
}
.degreeInfo{
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: 1rem;
    font-weight: 900;
}
.degreeInfo h1{
    text-align: left;
    font-size: 2.2rem;
}
.degreeInfo h2{
    font-size: 1.5rem;
    margin-top: 1rem;
}
.degreeInfo h3{
    float: right;
    font-size: 1.5rem;
}
.details{
    padding: 1rem;
    margin-left: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
}
ul li{
    list-style-type: square;
    margin-top: 1rem;
}

@media (max-width:768px) {
    .degreeMain{
        width: 90%;
        margin-top: 5rem;
    }
    .degreeCard{
        flex-direction: column;
        gap: 2rem;
    }
    .degreeImage{
        height: 15rem;
        width: 15rem;
        border-radius: 50%;
    }
    .degreeWrapper{
       margin-left: 0rem;
        width: 90%;
    }
    .degreeInfo h1{
        font-size: 1.8rem;
    }
    .degreeInfo h2{
        font-size: 1.4rem;
    }
    .degreeInfo h3{
        font-size: 1.2rem;
    }
}