* {
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	color: inherit;
	font-family: "Barlow Condensed";
}
html {
	scroll-behavior: smooth;
}
.App {
	width: 100%;
	position: relative;
}
.app-content {
	margin-top: 5rem;
}
@media (max-width: 768px) {
	.app-content {
		margin-top: 4rem;
	}
}

.fade-enter {
	opacity: 0;
	z-index: 1;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 0.3s ease-in;
}
@keyframes renderFromLeft {
	0% {
		opacity: 0;
		transform: translateX(-100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes renderFromRight {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
