.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
	background: rgba(0, 0, 0, 0.75);
}
.colorCard {
	height: 15rem;
	width: 8rem;
	position: fixed;
	left: auto;
	right: 4rem;
	top: 8rem;
	border-radius: 0.5rem;
	display: grid;
	grid-template-columns: repeat(2, 3rem);
	column-gap: 0.5rem;
	row-gap: 0rem;
	align-items: center;
	justify-content: center;
	z-index: 100;
	animation: fade 0.5s ease-out forwards;
}

.colorCard > div,
.colorCard > div:hover {
	height: 3rem;
	border-radius: 0.5rem;
	cursor: pointer;
	animation: zoom 0.2s ease forwards;
}
@media (max-width: 768px) {
	.colorCard {
		right: 1rem;
		top: 5rem;
		border-radius: 0.5rem;
	}
}
@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes zoom {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
