.centered {
	display: flex;
	align-items: center;
	justify-content: center;
}
.navbar {
	height: 5rem;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	z-index: 1000;
}
.logoContainer {
	margin-right: auto;
	margin-left: 5%;
	font-size: 2rem;
}
#logo{
	font-family: "Alex Brush";
}
.navsContainer {
	display: flex;
	gap: 1rem;
	margin-left: auto;
	flex-wrap: wrap;
	margin-right: 1rem;
}
.navsContainer a{
	text-decoration: none;
}
.navs {
	width: max-content;
	/* width: 6.5rem; */
	border-radius: 0.5rem;
	padding: 0.5rem 1rem 0.5rem 1rem;
	/* margin-left: 1rem; */
	font-size: 1.5rem;
}
.active {
	/* margin-left: 1rem; */
	font-size: 1.5rem;
	border-bottom-left-radius: 0rem;
	border-bottom-right-radius: 0rem;
	border-bottom: 3px solid purple;
	border-radius: 0.5rem;
}
.navs:hover{
	cursor: pointer;
	background-color: purple;
	color: white;
	/* border: 2px solid purple; */
	filter: brightness(110%);
	/* box-shadow: 0 0.5em 0.4em -0.4em purple; */
	/* transform: translateY(-0.2em); */
}
.selectTheme {
	margin-right: 2rem;
	display: flex;
	align-items: center;
	width: 5rem;
	justify-content: space-around;
}
.colorSelector {
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
}
.colorSelector:hover {
	cursor: pointer;
	transform: scale(1.1);
}
.selectMode {
	background-color: rgb(146, 15, 178);
	width: 5rem;
	height: 2rem;
	border-radius: 1rem;
	display: none;
	align-items: center;
	justify-content: space-evenly;
}
.selectMode:hover {
	cursor: pointer;
}
#lightModeBtn {
	margin-left: 0.5rem;
	margin-right: auto;
}
#darkModeBtn {
	margin-left: auto;
	margin-right: 0.5rem;
}
.selectTheme input{
	display: none;
}
.menu,.mob-nav {
	display: none;
}
.centered {
	display: flex;
	align-items: center;
	justify-content: center;
}
@media (max-width: 768px) {
	.navbar {
		height: 4rem;
	}
	.logoContainer {
		margin-left: 1rem;
		font-size: 1.5rem;
	}
	.navsContainer {
		display: none;
	}
	.selectTheme {
		width: 10rem;
		margin-left: auto;
		margin-right: 1rem;
		/* background-color: aqua; */
	}
	.colorSelector {
		margin-left: 1rem;
		height: 1.5rem;
		width: 1.5rem;
	}
	.selectMode {
		display: flex;
		width: 4rem;
		height: 1.8rem;
	}
	/* .menu{
		margin-right: 1.5rem;
	} */
	#burger-toggle {
		position: relative;
		appearance: none;
		opacity: 0;
	}
	#burger-toggle:checked ~ .menu {
		opacity: 1;
		visibility: visible;
	}
	#burger-toggle:checked ~ .menu .menu-nav-link span div,
	#burger-toggle:checked ~ .menu img,
	#burger-toggle:checked ~ .menu .title p {
		transform: translateY(0);
		transition: 1.2s 0.1s cubic-bezier(0.35, 0, 0.07, 1);
	}
	#burger-toggle:checked ~ .menu .image-link:nth-child(1) img {
		transition-delay: 0.18s;
	}
	#burger-toggle:checked ~ .menu .image-link:nth-child(2) img {
		transition-delay: 0.26s;
	}
	#burger-toggle:checked ~ .menu .image-link:nth-child(3) img {
		transition-delay: 0.34s;
	}
	#burger-toggle:checked ~ .menu .image-link:nth-child(4) img {
		transition-delay: 0.42s;
	}
	#burger-toggle:checked ~ .burger-menu .line::after {
		transform: translateX(0);
	}
	#burger-toggle:checked ~ .burger-menu .line:nth-child(1) {
		transform: translateY(calc(var(--burger-menu-radius) / 5)) rotate(45deg);
	}
	#burger-toggle:checked ~ .burger-menu .line:nth-child(2) {
		transform: scaleX(0);
	}
	#burger-toggle:checked ~ .burger-menu .line:nth-child(3) {
		transform: translateY(calc(var(--burger-menu-radius) / -5)) rotate(-45deg);
	}
	.burger-menu {
		/* margin-left: 1rem; */
		/* margin-right: 0rem; */
		--burger-menu-radius: 3em;
		position: relative;
		/* top: 5vh;
		left: 5vw; */
		/* z-index: 100; */
		display: block;
		width: var(--burger-menu-radius);
		height: var(--burger-menu-radius);
		outline: none;
		cursor: pointer;
		transition:all 1s ease-in-out;
	}
	.burger-menu .line {
		position: absolute;
		left: 25%;
		width: 50%;
		height: 3px;
		background: purple;
		border-radius: 10px;
		overflow: hidden;
		transition: 0.5s;
	}
	.burger-menu .line:nth-child(1) {
		top: 30%;
	}
	.burger-menu .line:nth-child(2) {
		top: 50%;
	}
	.burger-menu .line:nth-child(3) {
		top: 70%;
	}
	.burger-menu .line::after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--primary-color-darker);
		transform: translateX(-100%);
		transition: 0.25s;
	}
	.burger-menu .line:nth-child(2)::after {
		transition-delay: 0.1s;
	}
	.burger-menu .line:nth-child(3)::after {
		transition-delay: 0.2s;
	}
	.burger-menu:hover .line::after {
		transform: translateX(0);
	}

	.mob-nav{
		background-color: rgba(0, 0, 0, 0.578);
		position: fixed;
		top: 0;
		right: 10px;
		left: auto;
		margin-top: 4rem;
		height: 16rem;
		width: max-content;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		font-size: 1.3rem;
		border-radius: 0.5rem;
		padding: 0.5rem 1rem 0.5rem 1rem;
		animation: fade 0.5s ease-in forwards;
	}
	.mob-nav a{
		text-decoration: none;
	}
	.navs {
		margin-top: 1rem;
		width: max-content;
		/* width: 6.5rem; */
		border-radius: 0.5rem;
		padding: 0.5rem 1rem 0.5rem 1rem;
		font-size: 1.3rem;
		text-align: justify;
	}
	.navs{pointer-events: none;}
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}